<template>
  <nav class="navbar top is-fixed-top is-justify-content-center" role="navigation" aria-label="main navigation">
    <div class="navbar-brand is-hidden-tablet">
      <a class="navbar-item" @click="currentStep > 1 && currentStep < 6 ? currentStep-- : $router.back()">
        <Icon icon="chevron-left" />
        <span>{{ currentStep === 1 ? 'Home' : 'Back' }}</span>
      </a>
    </div>

    <progress class="progress is-hidden-tablet" max="100" :value="currentStep / 4 * 100"></progress>

    <div class="tabs mt-2 is-hidden-mobile">
      <ul class="container ml-auto">
        <li @click="currentStep > 1 && currentStep < 6 ? currentStep-- : $router.back()">
          <a>
            <Icon icon="chevron-left" />
            <span>{{ currentStep === 1 ? 'Home' : 'Back' }}</span>
          </a>
        </li>
        <li :class="currentStep === 1 && 'is-active'">
          <a>1. Pick your swag</a>
        </li>
        <li :class="currentStep === 2 && 'is-active'">
          <a>2. Set the Campaign</a>
        </li>
        <li :class="currentStep === 3 && 'is-active'">
          <a>3. Payment</a>
        </li>
        <li :class="currentStep === 4 && 'is-active'">
          <a>4. Finish</a>
        </li>
      </ul>
    </div>
  </nav>

  <div class="container ml-auto">
    <AddProducts
      v-if="currentStep === 1 && campaign"
      :campaign="campaign"
      :isPending="isPending"
      @updateCampaign="doUpdateCampaign(), currentStep = 2"
    />

    <AddInformation
      v-if="currentStep === 2"
      :campaign="campaign"
      :isPending="isPending"
      :campaignViewMode="`create`"
      @updateCampaign="doUpdateCampaign(), currentStep = 3"
    />

    <section v-if="currentStep === 3" class="section">
      <h3 class="subtitle">
        Payment
      </h3>

      <div class="columns">
        <div class="column">
          <div v-for="paymentMethod in paymentMethods" :key="paymentMethod.id">
            <Card :card="paymentMethod.card" class="mb-5">
              <button
                v-if="campaign.paymentMethod === paymentMethod.id"
                class="button is-ghost"
                disabled
              >
                Current
              </button>
              <button
                v-if="campaign.paymentMethod !== paymentMethod.id"
                class="button is-light"
                @click="campaign.paymentMethod = paymentMethod.id"
              >
                Use card
              </button>
            </Card>
          </div>

          <button :class="`button ${paymentMethods.length ? 'is-light' : 'is-black'}`" @click="showPaymentModal = true">
            + Add <span v-if="paymentMethods.length">&nbsp;new&nbsp;</span>payment method
          </button>

          <AddPaymentModal
            v-if="showAddPaymentModal"
            @close="showAddPaymentModal = false"
            @complete="showAddPaymentModal = false, campaign.paymentMethod = $event"
          />
        </div>
        
        <div class="column">
          <div class="box mb-5">
            <PaymentInfo
              :campaign="campaign"
              @emitAuthorized="campaign.authorized = $event"
            />

            <button class="button is-black is-fullwidth my-5" :disabled="!campaign.paymentMethod" @click="launchCampaign()">
              Submit Order
            </button>

            <p class="is-size-7 mb-3">This is a card authorization. You are authorizing the amount above to placed on your card but only the swag ordered by your recipients will be charged.</p>
            <p class="is-size-7 mb-3">The final amount will be charged on the campaign expiry date. Remaining funds will be returned to your card.</p>
            <p class="is-size-7 mb-3">We will sometimes substitute a missing item with similar product. Your substitution will always be a product of the same or better quality. If the substituted item costs more than the product you ordered, you will pay the lesser price of the product you originally selected.</p>
            <p class="is-size-7">By authorizing this payment, you agree to our <a href="https://swagible.com/terms-and-conditions/" target="_blank">Terms of Service</a> and <a href="https://swagible.com/terms-and-conditions/" target="_blank">Privacy Policy</a>.</p>
          </div>
        </div>
      </div>
    </section>

    <section v-if="currentStep === 4" class="section">
      <h1 class="title is-spaced mt-5 mb-3">
        Thank you for your order
      </h1>

      <p class="mb-6">
        An email confirmation has been sent to: {{ user.email }}
      </p>

      <label class="label">Campaign Order Details</label>

      <hr />

      <div class="columns is-mobile is-multiline">
        <div class="field column is-6">
          <label class="label">Authorized order total</label>
          <p>{{ formatCurrency(campaign.authorized * .01, 'CAD') }}</p>
        </div>
        <div class="field column is-6">
          <label class="label">Order date</label>
          <p>{{ formatDate(campaign.launchedAt) }}</p>
        </div>

        <div class="field column is-6">
          <label class="label">Payment Method</label>
          <Card :card="getPaymentMethod(campaign.paymentMethod)" />
        </div>
        <div class="field column is-6">
          <label class="label">Expiry date</label>
          <p>{{ formatDate(campaign.expiryDate) }}</p>
        </div>
      </div>

      <router-link :to="{ name: 'Dashboard' }" class="button is-black">
        Back to home
      </router-link>
    </section>

  </div>
</template>

<script>
import { ref, inject, watch } from 'vue'
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'
import useDocument from '@/composables/useDocument'
import useCollection from '@/composables/useCollection'
import getCollection from '@/composables/getCollection'
import Icon from '@/components/Icon'
import AddProducts from '@/components/CreateCampaign/AddProducts'
import AddInformation from '@/components/CreateCampaign/AddInformation'
import Product from '@/components/Product/Product'
import AddCard from '@/components/Payments/AddCard'
import Card from '@/components/Payments/Card'
import AddPaymentModal from '@/components/Modals/AddPaymentModal'
import PaymentInfo from '@/components/CreateCampaign/PaymentInfo'
import { timestamp } from '@/firebase/config'
// import { generateCampaignRecipientsHTML, generateCampaignItemsHTML } from '@/utils/utils'
import filters from '@/utils/filters'
import {userCampaignLiveEmail, recipientInviteEmail} from '@/utils/mailHandler.js'

export default {
  props: ['campaignId'],
  components: { Icon, AddProducts, AddInformation, Product, AddCard, Card, AddPaymentModal, PaymentInfo },
  beforeRouteLeave() {
    if (!this.campaign.campaignName) {
      const { deleteDoc: deleteCampaign } = useDocument('campaigns', this.campaignId)
      deleteCampaign()
    }
  },
  setup(props) {
    const { user } = getUser()
    const { document: account } = getDocument('accounts', user.value.uid)
    const { document: campaign } = getDocument('campaigns', props.campaignId)
    const { updateDoc: updateCampaign } = useDocument('campaigns', props.campaignId)
    const { documents: paymentMethods } = getCollection('stripe_customers', null, user.value.uid, 'payment_methods')
    
    const campaignViewMode = 'create'

    // If already launched, go to finaly step!
    watch(() => campaign.value, (campaign) => {
      if (campaign.launchedAt) currentStep.value = 4
    })

    const isPending = inject('isPending')

    const showAddPaymentModal = ref(false)
    const currentStep = ref(1)

    const doUpdateCampaign = async () => {
      // console.log('doUpdateCampaign', campaign.value)
      isPending.value = true
      await updateCampaign({
        ...campaign.value,
        lastUpdated: timestamp()
      })
      window.scrollTo({ top: 0 })
      isPending.value = false
    }

    const launchCampaign = async () => {
      console.log('launchCampaign', campaign.value)

      isPending.value = true
      let updateCampaignData = {
        accountCompany: account.value.company,
        paymentMethod: campaign.value.paymentMethod,
        authorized: filters.formatAmountForStripe(campaign.value.authorized, 'cad'),
        launchedAt: timestamp()
      }
      if (account.value.photoUrl) updateCampaignData.photoUrl = account.value.photoUrl
      if (account.value.logoUrl) updateCampaignData.logoUrl = account.value.logoUrl
      await updateCampaign(updateCampaignData)

      const { addDoc: addInvite } = useCollection('invites')
      const { addDoc: addEmail } = useCollection('mail')
      // const { updateDoc: updateMailTrigger } = useCollection('mailTriggers', user.value.uid)

      const { id, clients, ...campaignData } = campaign.value

      const promises = campaign.value.clients.map(async client => {
        console.log('client', client)
        if (client.email) {
          const invite = await addInvite({
            ...client,
            ...campaignData,
            campaignId: props.campaignId,
            created: timestamp()
          })
          console.log('invite', invite)

          console.log('Expiry', campaign.expiryDate)

          const res = await recipientInviteEmail(client, user, invite, props, campaign);

          // Moved to mailHandler.
          // const res = await addEmail({
          //   to: client.email,
          //   templateId: 'd-9ffec509f4c448f4beaa61dfefcc56ff', // SWAGIBLE:RECIPIENTINVITE
          //   dynamic_template_data: {
          //     recipient_first_name: client.name,
          //     accountHolder_first_name: user.value.displayName,
          //     campaignExpiryDate: formatDateAsMMMDD(props.campaign.expiryDate.seconds),
          //     first_name: user.value.displayName, //accountHolder_first_name again :/
          //     swag_url: `${process.env.VUE_APP_BASE_URL}/invite/${invite.campaignId}/${invite.id}`,
          //     decline_swag_url: `${process.env.VUE_APP_BASE_URL}/invite/${invite.campaignId}/${invite.id}?showDecline=true`
          //   }
          // })
          return res
          

          // TODO these triggers
          // var dt = new Date();
          // var ep = new Date(campaign.value.expiryDate)
          // const mailTriggers = {
          //   [`${invite.id}NotAccepted2Days`]: dt.setHours(dt.getHours() + 48),
          //   [`${invite.id}NotAccepted1Week`]: dt.setHours(dt.getHours() + 168),
          //   [`${invite.id}NotAcceptedExpiry`]: dt.setHours(ep.getHours() - 24),
          // }
          // await updateMailTrigger({
          //   ...mailTriggers
          // })
        }
      })
      await Promise.all(promises)

      await user.value.updateProfile({
        photoURL: 'complete'
      })

      userCampaignLiveEmail (user)
      
      // Moved to mailHandler.js
      // await addEmail({
      //   to: user.value.email,
      //   templateId: 'd-3b3b836460ea4a9586b60ba65605470f', // SWAGIBLE:CAMPAIGNLIVE
      //   dynamic_template_data: {
      //     accountHolder_first_name: user.value.displayName,
      //     dashboard_url: `${process.env.VUE_APP_BASE_URL}/dashboard`
      //   }
      // })

      const { addDoc: addNotification } = useCollection('accounts', user.value.uid, 'notifications')
      await addNotification({
        type: 'launchCampaign',
        message: `${campaignData.campaignName} is now live`,
        campaignId: id,
        seen: false,
        created: timestamp()
      })

      if (!user.value.photoURL) {

        await adminNotificationCampaignCreated (user, account, campaignData, campaign)

        // 
        // Moved to mailHandler.js
        // await addEmail({
        //   to: 'support@swagible.com',
        //   templateId: 'd-4b542f7e7b174a418ba2ef06614c1d06', // SWAGIBLE:ADMINCAMPCREATE
        //   dynamic_template_data: {
        //     accountholder_first_name: user.value.displayName,
        //     accountholder_last_name: user.value.displayName,
        //     accountholder_email: user.value.email,
        //     accountholder_company: account.value.company,
        //     campaign_name: campaignData.campaignName,
        //     campaign_expiry: filters.formatDate(campaignData.expiryDate),
        //     campaign_recipientsHTML: generateCampaignRecipientsHTML(campaign.value),
        //     campaign_itemsHTML: generateCampaignItemsHTML(campaign.value.products),
        //     dashboard_url: `${process.env.VUE_APP_BASE_URL}/dashboard`
        //   }
        // })
      }

      isPending.value = false
      currentStep.value = 4
    }

    const getPaymentMethod = (id) => {
      return paymentMethods.value && paymentMethods.value.find(paymentMethod => paymentMethod.id === id).card
    }
    
    return {
      user,
      campaign,
      paymentMethods,

      currentStep,
      showAddPaymentModal,

      doUpdateCampaign,
      launchCampaign,
      isPending,

      getPaymentMethod,

      ...filters,
    }
  }
}
</script>

<style scoped>
.section {
  padding-top: calc(1.5rem + 52px);
}

.navbar-start .navbar-item {
  cursor: default;
}

.navbar-start .navbar-item:hover {
  color: #22211F99;
}

.buttons {
  pointer-events: none;
}
</style>