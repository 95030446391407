<template>
  <div v-if="campaign" class="email-preview">
    <div class="level mb-0">
      <div class="avitar">
        M
      </div>
      <div class="ml-3 mr-auto">
        <p class="has-text-weight-bold">
          You've received a gift...
        </p>
        <p class="has-text-grey">
          {{ formatDate(new Date()) }}
        </p>
        <p>
          <span class="has-text-weight-bold">
            To: 
          </span>
          <span class="has-text-grey">
            test@email.com &gt;
          </span>
        </p>
      </div>
      <!-- <p class="has-text-grey is-align-self-flex-start">
        {{ formatDate(new Date()) }}
      </p> -->
    </div>
    <hr />

    <div class="body">
      <p v-if="campaign.clients[0]" class="mb-3">Hi {{ campaign.clients[0].name }},</p>
      <p v-else class="mb-3">Hi Joe,</p>
      <p v-if="campaign.message">{{ campaign.message }}</p>
      <p v-else>&lt; Your message here &gt;</p>
      <p class="my-3">You've received a gift!</p>
      <p class="mb-3">Swagible will coordinate your items being shipped directly to you.</p>
      <p class="mb-3">Your personal information will not be shared with {{ campaign.accountName }}.</p>
      <p v-if="campaign.expiryDate.toDate()">You have {{ formatDistanceStrict(new Date().getTime(), campaign.expiryDate.toDate().getTime()) }} to select your swag.</p>
    </div>

    <!-- <button class="button is-black is-fullwidth mt-5 disabled">
      Claim your swag
    </button> -->
  </div>
</template>

<script>
import { formatDistanceStrict } from 'date-fns'
import filters from '@/utils/filters'

export default {
  props: ['campaign'],
  setup() {
    
    return {
      formatDistanceStrict,
      ...filters
    }
  }
}
</script>

<style scoped>
.email-preview {
  margin: auto;
  pointer-events: none;
  background: url('~@/assets/iphone.png') no-repeat center;
  background-size: cover;
  height: 632px;
  width: 315px;
  padding: 4em 2.25em 2em;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
}

.level {
  white-space: nowrap;
}

.avitar {
  display: flex;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  background: linear-gradient(0.95deg, #858A94 -0.79%, #A3A8B5 94.42%);
}

.has-text-grey {
  color: #ccc;
}

.body p {
  font-weight: 500;
}
</style>