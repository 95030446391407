<template>

  <ExistingRecipientsModal
    v-if="campaignViewMode == 'create'"
    :clients="existingRecipients"
    @addClients="addExistingRecipients"
  />

  <div class="table-container mb-0">
    <table class="table is-fullwidth is-relative">
      <tbody>
        <tr v-for="recipient in recipients" :key="recipient.email">
          <td colspan="2">
            <label class="label mb-0">{{ recipient.name }}</label>
            <p class="help has-text-weight-normal">{{ recipient.email }}</p>
          </td>
          <td class="has-text-right pl-0" style="width: 0;">
            <button class="button is-ghost is-light mt-1" @click="removeRecipient(recipient)">
              <i class="fas fa-xmark"></i>
            </button>
          </td>
        </tr>
        
        <tr v-if="showAddAnother" class="add-client-row">
          <td>
            <FormField
              placeholder="email"
              type="email"
              v-model="blankClient.email"
              :class="!blankClient.name && 'hide-error'"
            />
          </td>
          <td colspan="2">
            <FormField
              placeholder="name"
              v-model="blankClient.name"
              @keyup.enter="addRecipient()"
            />
            <!-- Dont use <FormField> here because the blur doesnt work -->
            <!-- <div class="field">
              <label class="label"></label>
              <div class="control">
                <input
                  placeholder="name"
                  class="input"
                  v-model="blankClient.name"
                  @keyup.enter="addRecipient()"
                  @blur="addRecipient()"
                />
              </div>
            </div> -->
          </td>
        </tr>
        <tr>
          <td>
            <button
              v-if="showAddAnother"
              class="button is-ghost"
              :disabled="!blankClient.email || !blankClient.name"
              @click="addRecipient()"
            >
              <Icon icon="plus" />
              <span>Add recipient</span>
            </button>

         

            <button v-else class="button is-ghost" @click="showAddAnother = true">
              <Icon icon="plus" />
              <span>Add another</span>
            </button>
          </td>

          <td colspan="2" class="has-text-right">
            <ExistingRecipientsModal
            v-if="campaignViewMode == 'view'"
            :clients="existingRecipients"
            @addClients="addExistingRecipients"

            />
            <slot />
            
          </td>
          
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref } from 'vue'
import ExistingRecipientsModal from '@/components/Modals/ExistingRecipientsModal.vue'
import FormField from '@/components/Forms/FormField.vue'
import Icon from '@/components/Icon.vue'

export default {
  props: ['recipients', 'existingRecipients', 'campaignViewMode'],
  components: { ExistingRecipientsModal, FormField, Icon },
  setup(props) {
    const showAddAnother = ref(true)
    const blankClient = ref({ email: '', name: '' })

    const addExistingRecipients = (recipients) => {
      props.recipients.push(...recipients)
    }

    const addRecipient = () => {
      if (blankClient.value.email && blankClient.value.name) {
        props.recipients.push(blankClient.value)
        blankClient.value = { email: '', name: '' }
        showAddAnother.value = false
      }
    }

    const removeRecipient = (recipient) => {
      props.recipients.splice(props.recipients.indexOf(recipient), 1)
    }

    return {
      showAddAnother,
      blankClient,

      addExistingRecipients,

      addRecipient,
      removeRecipient,
    }
  }
}
</script>

<style module>
.button_right {
  margin-right: 0.25em
}
</style>

<style scoped>
.table td {
  vertical-align: top;
}

.table td:first-child {
  min-width: 0;
}

tr.add-client-row td, tr.add-client-row + tr td {
  border: 0;
}

.button.is-ghost {
  border: 0;
}

</style>