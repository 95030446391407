<template>
  <section class="section" style="padding-top: calc(1.5rem + 52px);">
    <h3 class="subtitle mb-5">
      Pick from our catalog of customizable products.
    </h3>

    <div class="columns is-multiline is-mobile">
      <div v-for="product in products" :key="product.id" class="column is-3-desktop is-4-tablet is-6-mobile">
        <Product
          :product="product"
          :isAdded="offeredProduct(product)"
          @toggleProduct="toggleProduct"
        />
      </div>
    </div>
  </section>

  <div class="button-footer">
    <button
      :class="`button is-black ${isPending && 'is-loading'}`"
      :disabled="!campaign.products.length"
      @click="$emit('updateCampaign')"
    >
      Next
    </button>
  </div>
</template>

<script>
import { watch } from 'vue'
import getCollection from '@/composables/getCollection'
import Product from '@/components/Product/Product.vue'
import filters from '@/utils/filters'

export default {
  props: ['campaign', 'isPending'],
  emits: ['updateCampaign'],
  components: { Product },
  setup(props) {
    const { documents: products } = getCollection('products')

    const offeredProduct = (product) => {
      return props.campaign.products.find(x => x.id === product.id)
    }

    const toggleProduct = (product) => {
      if (!offeredProduct(product)) {
        props.campaign.products.push(product)
      } else {
        const index = props.campaign.products.findIndex(x => x.id === product.id)
        if (index > -1) {
          props.campaign.products[index].chosenColors = []
          props.campaign.products.splice(index, 1)
        }
      }
    }

    watch(() => props.campaign.products.length, (length) => {
      props.campaign.chooseType = length
    })

    return {
      products,
      offeredProduct,
      toggleProduct,
      ...filters,
    }
  }
}
</script>