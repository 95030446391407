<template>
  <section class="section has-border" style="padding-top: calc(1.5rem + 52px);">
    <div class="columns">
      <div class="column is-6-tablet is-7-desktop">
        <h3 class="subtitle">
          Campaign Details
        </h3>

        <FormField
          label="Campaign Name"
          placeholder="Swagible Meeting"
          help="Give your campaign a name to stay organized. You will see it on your dashboard."
          v-model="campaign.campaignName"
        >
          <p
            v-if="!campaign.campaignName"
            id="campaignNameError"
            class="help is-danger"
          >
            {{ errors.campaignName }}
          </p>
        </FormField>

        <div class="field">
          <label class="label has-text-weight-normal">
            Campaign expiry date
          </label>
          
          <div class="control level is-mobile mb-0">
            <input
              type="date"
              class="input"
              v-model="computedDate"
              :min="new Date().toISOString().split('T')[0]"
              :max="new Date(new Date().setDate(new Date().getDate() + 30)).toISOString().split('T')[0]"
            />
            <div class="select ml-3">
              <select @change="computedDate = $event.target.value">
                <option
                  v-for="date in computedDates"
                  :key="date.value"
                  :value="date.value"
                >
                  {{ date.label }}
                </option>
              </select>
            </div>
          </div>
          <p class="help">
            Set a deadline for recipients to claim their swag manually, or from the easy options.
          </p>
        </div>

        <FormField
          label="How many items can each recipient pick?"
          type="tabs"
          :options="campaign.products.map((_, index) => {
            return { label: index + 1, value: index + 1 }
          })"
          v-model="campaign.chooseType"
        />
      </div>
      <div class="column is-4 is-hidden-mobile">
        <div class="box">
            <h3 class="subtitle mb-3">
            Items
          </h3>
          <OrderItem
            v-for="product in campaign.products"
            :key="product.id"
            :item="product"
          />
        </div>
      </div>
    </div>
  </section>

  <section class="section has-border">
    <div class="columns">
      <div class="column is-8-tablet is-7-desktop">

        <div class="level mb-5">
          <h3 class="subtitle">
            Campaign Type
            <span class="icon is-size-6 tooltip has-tooltip-multiline" data-tooltip="Public Mode: Ideal for conferences & events. This mode allows anyone to request swag via a link. You'll have the ability to approve/ reject requests before payment &amp; fulfillment.">
              <i class="fa-solid fa-circle-info"></i>
            </span>
          </h3>
          <div class="tabs mb-0">
            <ul>
              <li :class="campaign.mode === 'invite' && 'is-active'" @click="campaign.mode = 'invite'">
                <a>Invitation</a>
              </li>
              <li :class="campaign.mode === 'conference' && 'is-active'" @click="campaign.mode = 'conference'">
                <a>Public</a>
              </li>
            </ul>
          </div>
        </div>

        <FormField
          v-if="campaign.mode === 'conference'"
          label="Set a password (optional)"
          type="password"
          v-model="campaign.passcode"
          help="Recipients will be required to enter the password to access the campaign."
        />
        
        <AddRecipients
          v-if="campaign.mode === 'invite'"
          ref="recipientsComponent"
          :recipients="campaign.clients"
          :existingRecipients="campaign.clients"
          :campaignViewMode="`create`"
        />
        <p
            v-if="!campaign.clients.length"
            id="clientsError"
            class="help is-danger"
          >
            {{ errors.clients }}
          </p>
      </div>
    </div>
  </section>

  <section class="section">
    <div class="columns">
      <div class="column is-6-tablet is-6-desktop">
        <h3 class="subtitle">
          Personalization
        </h3>

        <p class="help mb-5">
          The recipient page initially displays the Swagible logo. Customize this page by replacing it with your or your client's logo. This is separate from the one used on your swag. This simple change adds a personal touch to your campaign.
        </p>

        <AddLogo />

        <FormField
          label="Personalize your message"
          type="textarea"
          :help="campaign.message ? `${campaign.message.length} / 300` : `0 / 300`"
          v-model="campaign.message"
        />
      </div>

      <div class="column is-5 is-hidden-mobile">
        <div class="box">
          <h3 class="subtitle mb-3">Personalized invitation preview</h3>
          <EmailPreview :campaign="campaign" />
        </div>
      </div>
    </div>
  </section>

  <div class="button-footer">
    <button
      :class="`button is-black ml-auto ${isPending && 'is-loading'}`"
      @click="handleSubmit()"
    >
      Proceed to Payment
    </button>
  </div>
</template>

<script>
import { ref, watch, reactive } from 'vue'
import FormField from '@/components/Forms/FormField.vue'
import AddRecipients from '@/components/CreateCampaign/AddRecipients.vue'
import AddLogo from '@/components/CreateCampaign/AddLogo.vue'
import OrderItem from '@/components/Campaign/OrderItem.vue'
import Datepicker from 'vue3-datepicker'
import Invite from '@/views/Invites/Invite.vue'
import EmailPreview from '@/components/CreateCampaign/EmailPreview.vue'
import firebase from 'firebase/app'
import filters from '@/utils/filters'
import { format, startOfDay, addWeeks, addMonths } from 'date-fns'

export default {
  props: ['campaign', 'isPending', 'campaignViewMode'],
  emits: ['updateCampaign'],
  components: { FormField, AddRecipients, Datepicker, Invite, AddLogo, OrderItem, EmailPreview },
  setup(props, { emit }) {
    const computedDates = ref([
      { label: '1 month', value: format(addMonths(startOfDay(new Date()), 1), 'yyyy-MM-dd') },
      { label: '2 weeks', value: format(addWeeks(startOfDay(new Date()), 2), 'yyyy-MM-dd') },
      { label: '1 week', value: format(addWeeks(startOfDay(new Date()), 1), 'yyyy-MM-dd') },
    ])
    
    const computedDate = ref(format(props.campaign.expiryDate.toDate(), 'yyyy-MM-dd'))

    watch(() => computedDate.value, (computedDate) => {
      props.campaign.expiryDate = firebase.firestore.Timestamp.fromDate(filters.cleanDate(computedDate))
    })

    const recipientsComponent = ref(null)

    watch(() => props.campaign.mode, (mode) => {
      if (mode === 'conference') props.campaign.clients = []
    })

    const errors = reactive({
      campaignName: '',
      clients: ''
    })

    const handleSubmit = () => {
      if (props.campaign.mode === 'invite') {
        recipientsComponent.value.addRecipient()
      }
      if (!props.campaign.campaignName) {
        errors.campaignName = 'Please enter a campaign name'
        document.getElementById('campaignNameError').scrollIntoView({ behavior: 'smooth', block: 'center' })
        return
      }
      if (props.campaign.mode === 'invite' && !props.campaign.clients.length) {
        errors.clients = 'Please enter at least 1 recipient'
        document.getElementById('clientsError').scrollIntoView({ behavior: 'smooth', block: 'center' })
        return
      }
      emit('updateCampaign')
    }

    return {
      computedDates,
      computedDate,
      recipientsComponent,

      errors,
      handleSubmit,
      ...filters
    }
  }
}
</script>

<style scoped>
.columns {
  justify-content: space-between;
}
</style>