<template>

  <div v-if="account" class="columns">
    <div v-if="account.logoUrl" class="column" style="max-width: 200px;">

      <div class="box">
        <figure class="image is-128x128">
          <img :src="account.logoUrl">
        </figure>
      </div>
    </div>
    <div class="column">
      <label :class="`button is-light ${progress.value && 'is-loading'}`">
        <input class="file-input is-clickable" type="file" @change="uploadLogo">
        Upload logo
      </label>
      <p class="is-size-7 mt-2">
        Image should be 300 x 80px.<br />SVG or transparent PNG supported.
      </p>
    </div>

    <p class="has-text-danger mb-3">{{ fileError }}</p>
  </div>
</template>

<script>
import { ref, watch, inject } from 'vue'
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument'
import useSignup from '@/composables/useSignup'
import useStorage from '@/composables/useStorage'

export default {
  setup() {
    const { user } = getUser()
    const { document: account } = getDocument('accounts', user.value.uid)
    const { updateProfile } = useSignup()
    const { uploadData, progress, error, resultData, deleteData } = useStorage('profilePhotos')

    const showUpload = ref(false)
    const fileError = ref(null)
    const photoFileTypes = ['image/png', 'image/jpeg']

    const uploadLogo = async (e) => {
      await deleteData(`/profilePhotos/${user.value.uid}/${account.value.logoPath}`)
      let selected = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0]
      if (selected && selected.size > 2000000) {
        fileError.value = 'Image is too large, please choose a file under 2MB'
        return
      }
      if (selected && photoFileTypes.includes(selected.type)) {
        fileError.value = null
        await uploadData(selected)
      } else {
        fileError.value = 'Please select an image file (png or jpg)'
      }
    }

    watch(() => resultData.value, (resultData) => {
      console.log('resultData', resultData)
      account.value.logoUrl = resultData.downloadUrl
      account.value.logoPath = resultData.name
      saveProfile()
    })

    const isPending = inject('isPending')
    const saveProfile = async () => {
      isPending.value = true
      await updateProfile(user.value.uid, {
        ...account.value
      })
      isPending.value = false
    }

    // const removeLogo = async () => {
    //   isPending.value = true
    //   await deleteData(`/profilePhotos/${user.value.uid}/${account.value.logoPath}`)
    //   account.value.logoUrl = ''
    //   account.value.logoPath = ''
    //   saveProfile()
    // }

    return {
      account,
      showUpload,
      uploadLogo,
      progress,
      fileError,
      // removeLogo
    }
  }
}
</script>

<style scoped>
.profile-photo {
  width: 7em;
  min-width: 7em;
  height: 7em;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 8px 8px rgb(10 10 10 / 10%);
}

.profile-photo .button {
  width: 30px;
  position: absolute;
  top: -.5rem;
  right: -.5rem;
}

/* .edit-photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
} */

/* .profile-photo .icon {
  margin-top: 2rem;
} */
</style>