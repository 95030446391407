<template>
  <div class="level is-mobile is-justify-content-flex-start mb-0" style="white-space: nowrap;">
    <figure class="image pt-1">
      <Icon :icon="`fa-brands fa-cc-${card.brand}`" size="3" />
    </figure>
    <p class="ml-3">
      ending in {{ card.last4 }}
    </p>

    <span v-if="isActive" class="icon has-text-brand ml-3">
      <i class="fa-solid fa-check"></i>
    </span>

    <div class="is-flex ml-auto">
    <slot />
  </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'

export default {
  props: ['card', 'isActive'],
  components: { Icon },
}
</script>

<style scoped>
.is-active {
  border: 3px solid #fff;
  outline: 3px solid var(--brandColor);
  border-radius: .5rem;
}
</style>