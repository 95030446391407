<template>
  <h2 class="subtitle">
    Order Summary
  </h2>
  <div class="level is-mobile has-text-weight-bold">
    <span>Maxmimum Product Total</span>
    <span v-html="formatCurrency(subtotal, 'CAD')" />
  </div>
  <div class="level is-mobile">
    <span>No. of recipients</span>
    <span>{{ campaign.clients.length }}</span>
  </div>
  <div class="level is-mobile">
    <span>Order shipping</span>
    <span v-html="formatCurrency(shipping, 'CAD')" />
  </div>
  <div class="level is-mobile">
    <span>Swagible fee</span>
    <span v-html="formatCurrency(swagibleFee, 'CAD')" />
  </div>
  <div v-if="includeTax" class="level is-mobile">
    <span>Order tax</span>
    <span v-html="formatCurrency(tax, 'CAD')" />
  </div>

  <hr class="has-background-black" style="height: 1px;" />

  <h3 class="level is-mobile has-text-weight-bold">
    <span>Maximum order Total</span>
    <span v-html="formatCurrency(total, 'CAD')" />
  </h3>
</template>

<script>
import { computed } from 'vue'
import filters from '@/utils/filters'

export default {
  props: ['campaign'],
  emits: ['emitAuthorized'],
  setup(props, { emit }) {
    const includeTax = process.env.VUE_APP_INCLUDE_TAX

    const clientLength = props.campaign.mode === 'invite' ? props.campaign.clients.length : 1
    const shipping = 10 * clientLength

    const subtotal = computed(() => {
      let total = 0
      const sorted = props.campaign.products.sort((a, b) => b.charge - a.charge)
      for (var i = 0; i < props.campaign.chooseType; i++) {
        let product = sorted[i]
        if (product) {
          total += Number(sorted[i].charge)
        }
      }
      return total * clientLength
    })

    const swagibleFee = computed(() => {
      const total = subtotal.value * .10
      return Math.round(total * 100) / 100
    })

    const tax = computed(() => {
      if (!includeTax) return 0
      const total = (subtotal.value + shipping + swagibleFee.value) * .13
      return Math.round(total * 100) / 100
    })

    const total = computed(() => {
      let total = (subtotal.value + shipping) + tax.value + swagibleFee.value
      total = Math.round(total * 100) / 100
      emit('emitAuthorized', total)
      // campaign with 1 order nad just shorts should emit $38.02... was 37.17 b4
      return total
    })

    return {
      includeTax,

      subtotal,
      shipping,
      tax,
      swagibleFee,
      total,

      ...filters,
    }
  }
}
</script>